import React from 'react'
import SelectCountryScreen from './SelectCountryScreen'

const index = () => {
  return (
    <>
    <SelectCountryScreen/>
    </>
  )
}

export default index