import React from 'react'
import SelectCountry from '../../view/SelectCountry/index'
const index = () => {
  return (
    <>
    <SelectCountry/>
    </>
  )
}

export default index