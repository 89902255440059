import React from 'react'
import StepStructure from './StepStructure';

const index = () => {
  return (
    <>
     <StepStructure/>
    </>
  );
}

export default index