import React from 'react'

const PageSubHeading = ({subheading}) => {
  return (
    <>
      <p className="">{subheading}</p>
    </>
  );
}

export default PageSubHeading