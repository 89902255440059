import React, { useEffect, useState } from "react";
import webimg from "../../assets/girlimage3.png";
import yellowicon from "../../assets/yellowright-icon.svg";

const Index = () => {
  const [userName, setUserName] = useState("");
  // const [storeData, setStoreData] = useState();
  const getUserName = () => {
    if (localStorage.getItem("login_data")) {
      let loginData = JSON.parse(localStorage.getItem("login_data"));
      setUserName(loginData?.first_name);
    }
  };
  useEffect(() => {
    getUserName();
    // let documents = JSON.parse(localStorage.getItem("documents"));
    // console.log("documents", documents);
    // setStoreData(documents);
  }, []);

 

  return (
    <>
     

      <div id="thankyoupage">
        <div className="container">
          <div className="row">
            <div className="text-center m-auto">
              <h1>
                <b>Willkommen {userName}</b>
              </h1>
              <p className="title-text">
                <b> ​🎉​Glückwunsch zu Ihrem Lebenslauf!</b> Zeit, Ihre Chancen
                auf mehr Vorstellungsgespräche zu erhöhen.
              </p>
            </div>
          </div>
          <div className="mt-5">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 mt-2">
                <div className="image-content">
                  <img src={webimg} alt="" />
                </div>
              </div>
              <div className="col-lg-6 mt-2">
                <div className="text-details">
                  <div className="pipe_line">
                    <h4 className="sup-title">
                      💡Entdecken Sie die einfachsten Wege zum Aufbau Ihrer{" "}
                      <span className="yellow_text">Bewerbung</span> !
                    </h4>
                  </div>
                  <h1 className="site-title">
                    Bewerbung schreiben <span className="yellow_text">lassen</span>
                  </h1>
                  <ul className="mb-3 list-icon">
                    <li>
                      <img src={yellowicon} alt="" />
                      Unterstützung von Fachleuten in Ihrem Fachgebiet
                    </li>
                    <li>
                      <img src={yellowicon} alt="" /> Personalisierte
                      Bewerbungsdienstleistungen
                    </li>
                    <li>
                      {" "}
                      <img src={yellowicon} alt="" /> Zeitersparnis
                    </li>
                  </ul>
                </div>

                {/* <div className="thanks-btn">
                  <a href="https://bewerbungschreiben-online.de">
                    ANSCHREIBEN BESUCHEN
                  </a>
                </div> */}

                <a
                  className="btn_create"
                  href="https://bewerbungschreiben-online.de"
                >
                  Jetzt Bewerbung schreiben lassen
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* {storeData} */}
      </div>
      
    



      
    </>
  );
};

export default Index;
