import React from "react";
import ExpLevel from "../../view/ExpLevel/index";
const index = () => {
  return (
    <>
      <ExpLevel />
    </>
  );
};

export default index;
