import React from 'react'
import StepScreen from '../../view/StepScreen/index'
const index = () => {
 
  return (
    <>
      <StepScreen />
    </>
  );
}

export default index