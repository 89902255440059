import React from 'react'
import FinalStructure from './FinalStructure'

const index = () => {
  return (
    <>
    <FinalStructure/>
    </>
  )
}

export default index