import React from 'react'
import EductaionDetailForm from "../../view/EducationDetailForm/index";

const index = ({handleRemoval}) => {
  return (
    <>
     <EductaionDetailForm handleRemoval={handleRemoval}/>
    </>
  );
}

export default index