import React from 'react'
import EducationSummary from '../../view/EducationSummary/Index'

const Index = () => {
  return (
    <>
    <EducationSummary/>
    </>
  )
}

export default Index