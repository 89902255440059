import React from "react";
import right from "../../assets/greenright.svg";
import Slider from "react-slick";

const ThankyouHeader = () => {
  const settings = {
    // className: "center",
    dots: false,
    infinite: true,
    loop: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows:false,
    // centerMode: true,
    // centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="bar_slidder">
      <div className="container">
        <Slider {...settings} className="">
          <div className="d-flex gap-3 align-items-center justify-content-center mb-2">
            <img src={right} alt="" width={20} height={15} />
            <p className="text-small mb-0">Uber 99% zufriedene Kunden</p>
          </div>
          <div className="d-flex gap-3 items-center justify-content-center mb-2">
            <img src={right} alt="" width={20} height={15} />
            <p className="text-small mb-0">
              4 von 5 Kunden landen im Vorstellungsgespräch
            </p>
          </div>
          <div className="d-flex gap-3 items-center justify-content-center mb-2">
            <img src={right} alt="" width={20} height={15} />
            <p className="text-small mb-0">Zufrieden oder Geld zurück</p>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default ThankyouHeader;
