import React from 'react'
import EduSummary from './EduSummary'

const Index = () => {
  return (
    <>
    <EduSummary/>
    </>
  )
}

export default Index