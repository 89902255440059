import React from 'react'
import FinalResume from '../../view/FinalResume/index'
const index = () => {
  return (
    <>
    <FinalResume/>
  
    </>
  )
}

export default index